import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, BaseButton } from "../components/styledComponents"

const NotFoundPage = ({ data }) => {
  const {
    notFoundTitle,
    notFoundText,
    notFoundHomeButton,
  } = data.wordpressPage.cmb2.global_settings
  return (
    <Layout>
      <SEO title={notFoundTitle} path="/404" />
      <Container>
        <div
          style={{
            textAlign: "center",
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1>{notFoundTitle}</h1>
          <p>{notFoundText}</p>
          <BaseButton to="/">{notFoundHomeButton}</BaseButton>
        </div>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query NotFoundPageQuery {
    wordpressPage(template: { eq: "page-templates/page-global-settings.php" }) {
      cmb2 {
        global_settings {
          notFoundHomeButton
          notFoundText
          notFoundTitle
        }
      }
    }
  }
`

export default NotFoundPage
